import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout";
import PageHeader from "../components/PageHeader";
import LeadForm from "../components/Contact";

const TeamMemberPageTemplate = ({ pageContext, data }) => {

  const translations = {
    en: {
      title: 'Hong Kong Chiropractors',
      slogan: '"Doctors heal by triggering certain positive reactions, but healing occurs from inside the body. The body is the real doctor. The healer only facilitates the process of healing.”',
      backButton: 'Back to Meet Our Team',
    },
    ch: {
      title: '香港護脊中心',
      slogan: '「醫生的醫治是為了引發某些正面的反應，修復能力卻是來自身體內部。病者的身體才是真正的醫生。治療只是促進了自癒的過程而已。」',
      backButton: '返回我們的團隊'
    }
  }
  const { chiropractor } = data.firestore;

  return (
    <Layout context={pageContext} openGraph={{
      url: pageContext.localizedUrls[pageContext.locale],
      title: pageContext.title,
      description: pageContext.description,
      images: [
        {
          url: chiropractor.image
        }
      ]
    }}>
      <PageHeader
        title={pageContext.locale==='en'?`Our Team - ${chiropractor.name}`:`我們的團隊 - ${chiropractor.name}`}
        imageUrl="/_img/banners/team.jpg"
      />
      <main className="my-12 flex flex-col justify-center items-center mx-auto">
        <h2 className="text-coconut font-semibold text-xl lg:text-4xl">{translations[pageContext.locale]['title']}</h2>
        <hr className="border-mango border w-8 my-6"/>
        <p className="mx-4 lg:mx-0 text-brick text-base lg:text-xl text-center max-w-5xl">{translations[pageContext.locale]['slogan']}</p>
        <section className="max-w-5xl">
          <div className="flex flex-col lg:flex-row justify-between items-center lg:items-start mt-16 space-y-4 lg:space-y-0">
            <img className="rounded-lg shadow-lg max-h-56 lg:max-h-full" src={chiropractor.image} alt={chiropractor.name}/>
            <aside className="w-full lg:w-3/5 flex flex-col items-center lg:items-start">
              <div className="flex items-baseline">
                <h2 className="text-coconut font-semibold text-xl lg:text-3xl mb-2">{chiropractor.name}</h2>
                <h3 className="ml-2 text-brick text-lg lg:text-xl">{chiropractor.title}</h3>
              </div>
              <h3 className="text-brick text-lg lg:text-xl">{chiropractor.qualifications}</h3>
              <div className="prose-sm lg:prose mx-4 lg:mx-0" dangerouslySetInnerHTML={{__html: chiropractor.description}}></div>
            </aside>
          </div>
        </section>
        <div className="mt-8 flex space-x-4">
          <a href={`/${pageContext.locale}/about-us/team/`} className="text-white bg-brick rounded-md px-6 py-3">{translations[pageContext.locale]['backButton']}</a>
        </div>
      </main>
      <LeadForm locale={pageContext.locale} />
    </Layout>
  )
}

export default TeamMemberPageTemplate

export const query = graphql`
  query TeamMemberPageTemplateQuery($locale: String! $pageId: ID) {
    firestore {
      chiropractor(locale: $locale, id: $pageId) {
        id
        name
        title
        qualifications
        description
        image
      }
    }
  }
`